<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="名称" prop="areaName" v-if="form.id">
        <a-input v-model="form.areaName" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="省份" prop="provinceName" v-else-if="level == 1">
        <al-cascader v-model="resArr" :level="level" data-type="name"/>
      </a-form-model-item>
      <a-form-model-item label="城市" prop="cityName" v-else-if="level == 2">
        <al-cascader v-model="resArr" :level="level" data-type="name"/>
      </a-form-model-item>
      <a-form-model-item label="区/县" prop="areaName" v-else-if="level == 3">
        <al-cascader v-model="resArr" :level="level" data-type="name"/>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入"/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { areaInfo, areaAdd, areaEdit } from '@/api/pages/region'
import AlCascader from '@/components/AlCascader/al-cascader'

export default {
  name: 'CreateForm',
  components: {
    AlCascader
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      open: false,
      resArr: null,
      level: 1,
      // 表单
      form: {
        id: 0,
        provinceName: '',
        cityName: '',
        areaName: ''
      },
      rules: {
        provinceName: [{ required: true, message: '请选择区域', trigger: 'blur' }],
        cityName: [{ required: true, message: '请选择区域', trigger: 'blur' }],
        areaName: [{ required: true, message: '请选择区域', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
    resArr: function(n, o) {
      console.log(n)
      this.form.provinceName = n[0]
      this.form.cityName = n[1]
      this.form.areaName = n[2]
      console.log(this.form)
    }
  },
  methods: {
    changeArea(e) {
      console.log(e)
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: 0,
        provinceName: '',
        cityName: '',
        areaName: ''
      }
    },
     /** 新增按钮操作 */
    handleAdd (level, pid, arr) {
      this.reset()
      this.open = true
      this.level = level
      if (pid) {
        this.form.parentId = pid
      }
      this.resArr = arr || []
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      areaInfo({ id: configId }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            areaEdit(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            areaAdd(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
